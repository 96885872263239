/**
 * All the styles related with the pages of the application.
 * The file is being loaded in app.scss
 */

/* Sections */
/* =============================================================== */
section.top {

    padding: 100px 0 100px 0;

    h2 {
        color: #fff;
        font-size: 68px;
        font-weight: bold;
    }

    p {
        color: #fff;
        font-size: 18px;
    }

}

section.gradient {
    padding: 125px 0 125px 0;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.25) !important;
}

section.main {
    padding: 75px 0;
}

@media (max-width: 767.98px) {

    section.top {

        padding: 50px 0 50px 0;

        h2 {
            color: #fff;
            font-size: 42px;
            font-weight: 700;
        }

        p {
            color: #fff;
            font-size: 16px;
        }

    }

    section.gradient {
        padding: 75px 0;

        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    section.main {
        padding: 75px 0;
    }

}

/* Typography */
/* =============================================================== */
h2 {
    line-height: 1.3;
}

.main-title {
    font-size: 38px;
    font-weight: bold;
}

.sub-title {
    font-size: 18px;
}

.top-title {
    font-size: 18px;
    font-weight: bold;
}

/* Cards */
/* =============================================================== */
.card-hover {

    &:hover {
        color: $primary !important;
    }

}

/* Keyframe */
/* =============================================================== */
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
