/**
 * All the styles related with the footer of the application.
 * The file is being loaded in app.scss
 */

footer {

    color: #647083;

    .title {
        color: #1b3548;
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
    }

    ul {
        li {
            margin-top: 1rem;
            a {
                color: #647083;
            }
        }
    }

}
