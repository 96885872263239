/**
 * All the styles related with the toastr notifications of the application.
 * The file is being loaded in app.scss
 */

/* General. */
/* =============================================================== */
#toast-container > div {
    box-shadow: none;
    margin: 0px;
    padding: 15px;
    box-shadow: none;
}
#toast-container > .toast-success,
#toast-container > .toast-error {
    background-image: none !important;
}
#toast-container > div:hover {
    box-shadow: none;
    opacity: 1;
    cursor: default;
}
.toast-message {
    text-align: center;
}
.toast-success {
    background-color: #2f5ada;
}
.toast-error {
    background-color: #dc3545;
}
