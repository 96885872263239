/**
 * All the styles related with the navbars of the application.
 * The file is being loaded in app.scss
 */

/* Main navbar. */
/* =============================================================== */
.navbar {
    border-radius: 0px;
    transition: 300ms ease;

    .nav-link {
        font-weight: 600;
        font-size: .875rem;
    }

    .dropdown-menu {
        margin: 0.45rem 0 0;
    }
}

.navbar-light {
    .navbar-nav {
        .nav-link {
            color: #0A2540;
        }
    }
}

@media (max-width: 767.98px) {
    .navbar {
        .navbar-nav {
            padding: 0.5em 0.2rem;
        }
    }
}

/* General */
/* =============================================================== */
.nav-divider {
    text-transform: uppercase;
    color: #ddd !important;
    padding: 0.4rem;
    &:before {
        content: "|";
    }
}

@media (max-width: 767.98px) {
    .nav-divider {
        display: none;
    }
}
