/**
 * All the styles related with the application.
 * The file is being loaded in app.scss
 */

/* General */
/* =============================================================== */
html {
    scroll-behavior: smooth;
}

/* Colors */
/* =============================================================== */
.bg-dark {
    background-color: #212121 !important;
}

/* Pagination */
/* =============================================================== */
.pagination {
   justify-content: center;
}

/* Badges */
/* =============================================================== */
.badge {
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    letter-spacing: .5px;
    vertical-align: middle;
}

/* Tooltip */
/* =============================================================== */
[data-toggle='tooltip'] {
    cursor: default;
    border-bottom: 1px dotted;
}

/* Underline */
/* =============================================================== */
.custom-underline {
    background-image: linear-gradient(90deg, #F34FB5, #958AF1);
    background-position: bottom;
    background-size: 100% 10%;
    background-repeat: no-repeat;
}
