/**
 * All the styles related with the forms of the application.
 * The file is being loaded in app.scss
 */

/* General. */
/* =============================================================== */
.form-title {
    font-size: 20px;
}

label.required {
    &:after {
        color: $danger;
        content: ' *';
    }
}

/* Images */
/* =============================================================== */
.image-block {
    .btn-remove-image {
        position: absolute;
        top: 0px;
        left: 15px;
        padding: 2px 7px !important;
    }
}

/* Payments */
/* =============================================================== */
#payment-form {
    .form-control {
        font-size: 0.9rem;
    }
}
