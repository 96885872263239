/**
 * All the styles related with the buttons of the application.
 * The file is being loaded in app.scss
 */

/* Buttons general styles. */
/* =============================================================== */
.btn-lg {
    font-size: 0.95rem;
}
