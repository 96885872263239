/**
 * All the styles related with the variables of the application.
 * The file is being loaded in app.scss
 */

/* Body */
/* =============================================================== */
$body-bg: #ffffff;
$body-color: #3C4043;

/* Typography */
/* =============================================================== */
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

/* Colors */
/* =============================================================== */
$blue: #4267B2;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #c99f50;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
